
export default function Wiki() {
	return (
		<>
			<img
				src="https://www.netanimations.net/fire-under-construction-animation.gif"
				alt="Under Construction"
			></img>
			<b>wiki goes here</b>
		</>
	);
};