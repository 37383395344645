export default function Player() {
	return (
        <>
            <img
                src="https://www.netanimations.net/fire-under-construction-animation.gif"
                alt="Under Construction"
            ></img>
            <b>player goes here</b>
        </>
	);
};