import { TablePagination, TableHead, Table, TableContainer, TableRow, TableCell, TableBody, Paper, Card } from "@mui/material";
import React, { useState, useEffect } from 'react';
import { useFetch } from "../../hooks/useFetch";
import { Link } from "react-router-dom";



interface SkoPlayerMeta {
  playerName: string;
  playerClan: string;
};

interface SkoStatusMeta{
  playersOnline: number;
  players: SkoPlayerMeta[]
};

export default function SkoStatus() {

    const [resultsPerPage, setResultsPerPage] = useState(10);
    const [tablePage, setTablePage] = useState(1);
    const [data, setData] = useState({} as SkoStatusMeta);

    const loadData = async () => {
      //const response = await fetch('http://localhost:8080/meta', {
      //const response = await fetch('https://paladin.stickknightsonline.com/meta', {
      const response = await fetch('https://paladin.stickknightsonline.com/meta', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      });
  
      var dat = await response.json();

      console.log(dat);

      setData(dat);
    };

    useEffect(() => {
      loadData();
      return () => {};
    }, []);
  
    const PlayerLink = (props: {playerName: string}) => (
      <Link to={`player/${props.playerName}`}>
          {props.playerName}
      </Link>
    );

    const ClanLink = (props: {clanName: string}) => (
      <Link to={`clan/${props.clanName}`}>
          [{props.clanName}]
      </Link>
    );

    return(
      <Card>
        {data?.players?.length === 1 &&
          <h4>1 playing SKO right now:</h4>
        }
        {data?.players?.length === 0 &&
          <h4>Nobody playing SKO right now. </h4>
        }
        {data?.players?.length > 1 &&
          <h4>{data.playersOnline} playing SKO right now:</h4>
        }
        
        {data?.players?.map((player: SkoPlayerMeta) => {
          return (
            <p>
              <PlayerLink playerName={player.playerName} />
              {player.playerClan && 
                <>
                  <b> - </b>
                  <ClanLink clanName={player.playerClan} />
                </>
              }
            </p>
          )
        })}
      </Card>
    );

}