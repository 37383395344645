import React from "react";
import "./App.css";

import Navigation from "./components/Navigation";
import {BrowserRouter, Route, Link} from "react-router-dom";
import Container from "@mui/material/Container";
import { Box } from "@mui/system";
import Home from "./pages/home";  
import Donate from "./pages/donate";
import News from "./pages/news";
import Leaderboard from "./pages/leaderboard";
import Wiki from "./pages/wiki";
import Player from "./pages/player";

function App() {
	return (
		<Container maxWidth="md">
			<Box sx={{ bgcolor: '#cfe8fc', height: '100vh' }}>
				<BrowserRouter>
					<Navigation />
					<div className="SKO">
						<Route path="/leaderboard" component={Leaderboard} />
						<Route path="/wiki" component={Wiki} />
						<Route path="/news" component={News} />
						<Route path="/donate" component={Donate} />
						<Route path="/player" component={Player} />
						<Route path="/" exact component={Home} />
					</div>
				</BrowserRouter>
			</Box>
		</Container>
	);
}

export default App;
