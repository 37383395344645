import { TablePagination, TableHead, Table, TableContainer, TableRow, TableCell, TableBody, Paper, TableFooter } from "@mui/material";
import LeaderboardTableRow, { LeaderboardTableRowEmpty } from "./LeaderboardTableRow";
import React, { useState, useEffect } from 'react';

import { styled } from '@mui/system';
import TablePaginationUnstyled, {
  tablePaginationUnstyledClasses as classes,
} from '@mui/base/TablePaginationUnstyled';

const Root = styled('div')`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }

  th {
    background-color: #ddd;
  }
`;

const CustomTablePagination = styled(TablePaginationUnstyled)`
  & .${classes.toolbar} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
  }

  & .${classes.displayedRows} {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.spacer} {
    display: none;
  }

  & .${classes.actions} {
    display: flex;
    gap: 0.25rem;
  }
`;

interface SkoLeaderboardMeta {
  playerCount: number; 
  onlineCount: number;
}

export default function LeaderboardTable() {

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = useState([]);
  const [metaData, setMetaData] = useState([] as SkoLeaderboardMeta[] );

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const loadMetaData = async () => {
    try {
      const response = await fetch('https://api.stickknightsonline.com/leaderboard/meta/', {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })

      setMetaData(await response.json());
    } catch (exception) {
      console.log("Error getting leaderboard meta.");
    }
  }

  const loadData = async (page: number, rowsPerPage: number) => {
    //const response = await fetch('http://localhost:3404/leaderboard/1/10', {
    //const response = await fetch('https://api.stickknightsonline.com/leaderboard/1/10', {
    
    try {
      const response = await fetch(`https://api.stickknightsonline.com/leaderboard/${page + 1}/${rowsPerPage}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })

      setData(await response.json());
    } catch (exception) {
      console.log("Error getting leaderboard page: ", page, " and rowsPerPage: ", rowsPerPage);
    }


  };

  useEffect(() => {
    loadData(page, rowsPerPage);
    loadMetaData();
    return () => {};
  }, [page, rowsPerPage]);

  console.log("meta: ", metaData);

  return(
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }} aria-label="simple table" stickyHeader >
        <TableHead>
          <TableRow>
            <TableCell align="center">Rank</TableCell>
            <TableCell align="left">User&nbsp;Name</TableCell>
            <TableCell align="left">Clan</TableCell>
            <TableCell align="left">Level</TableCell>
            <TableCell align="right">Next&nbsp;Level</TableCell>
            <TableCell align="right">Operating&nbsp;System</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: any, index: number) => {
              const dataRow: any = { 
                rank: 1 + index + (page) * rowsPerPage, 
                ...row 
              };

              return <LeaderboardTableRow {...dataRow}  key={row.playerId} />;
            }
          )}
          {
            rowsPerPage > data.length && 
            (
            // Add empty rows on the final page.
            [...Array(rowsPerPage-data.length)].map((e, i) => {
                return <LeaderboardTableRowEmpty />;
              })
            )
          }
        </TableBody>
        <TableFooter>
          <TableRow>
          <CustomTablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              colSpan={3}
              count={metaData[0]?.playerCount ?? data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}