
import './Navigation.css';

import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import DownloadButton from './DownloadButton'
import Avatar from '@mui/material/Avatar';
import { deepPurple } from '@mui/material/colors';
import ButtonGroup  from '@mui/material/ButtonGroup';
import { Leaderboard as LeaderboardIcon } from "@mui/icons-material";


interface NavigationLinkProps {
  to: string;
  name: string;
}

function NavigationLink(props: NavigationLinkProps) {

  const {to, name} = props;

  return (
      <Button href={to}>
        <Typography component="div" sx={{ flexGrow: 1, color: '#FFF', mr: 2 }}>
          {name}
        </Typography>
      </Button>
  );
  
}

export default function Navigation() {
  return (
    <Box sx={{ bgcolor: 'lightgray'}}> 
      <AppBar position="static">
        <Toolbar>
            <NavigationLink to="/" name="Home" />
            <NavigationLink to="/news" name="News" />
            <NavigationLink to="/leaderboard" name="Leaderboard" />
            <DownloadButton link="https://www.stickknightsonline.com/Stick-Knights-Online-Setup.msi" icon="../../icon.png" text="Download Beta" text-animation="true" icon-animation="true" />

            <NavigationLink to="/player" name="Player" />
            <NavigationLink to="/wiki" name="Wiki" />
            <NavigationLink to="/donate" name="Donate" />
      
      {false && <>
          <Avatar sx={{ bgcolor: deepPurple[500] }}>OP</Avatar>
          <Button color="inherit">Logout</Button>
          </>
      }
        </Toolbar>
        
      </AppBar>
    </Box>


    // <Navbar bg="light" expand="lg">
    //   <Navbar.Brand href="#home">Stick Knights Online</Navbar.Brand>
    //   <Navbar.Toggle aria-controls="basic-navbar-nav" />
    //   <Navbar.Collapse id="basic-navbar-nav">
    //     <Nav className="mr-auto">
    //       <Link href="#link">Leaderboard</Link>
    //       <Link href="#link">Wiki</Link>
    //       <Link href="#link">Community</Link>
    //       <Link href="#link">GitHub</Link>
    //       <NavDropdown title="Profile" id="basic-nav-dropdown">
    //         <NavDropdown.Item href="#action/3.1">Log in</NavDropdown.Item>
    //         <NavDropdown.Item href="#action/3.2">Account</NavDropdown.Item>
    //         <NavDropdown.Item href="#action/3.3">Link SKO Character</NavDropdown.Item>
    //         <NavDropdown.Divider />
    //         <NavDropdown.Item href="#action/3.4">Register Account</NavDropdown.Item>
    //       </NavDropdown>
    //     </Nav>
    //     <DownloadButton link="https://www.stickknightsonline.com/Stick-Knights-Online-Setup.msi" icon="../icon.png" text="Download Beta" text-animation="true" icon-animation="true" />
    //   </Navbar.Collapse>
    // </Navbar>
  );
}

