import LeaderboardTable from "../components/leaderboard/LeaderboardTable";
import SkoStatus from "../components/skostatus/SkoStatus";

export default function Leaderboard() {
	return (
        <>
            <SkoStatus/>
            
            <h4>Top 10 Leaderboard</h4>
            <LeaderboardTable />
        </>
	);
};