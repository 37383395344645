import { TableRow, TableCell, Typography, CardContent, Card, CardHeader, Avatar, Stack, Divider } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Link } from "react-router-dom";
import { useState } from "react";

export interface LeaderboardTableRowProps{
    rank: number;
    clanName: string;
    def: number;
    gold: number;
    hp: number;
    hp_max: number;
    level: number;
    minutes_played: number;
    operatingSystem: string;
    playerId: number;
    playerName: string;
    regen: number;
    str: number;
    xp: number;
    xp_max: number;
}


export function LeaderboardTableRowEmpty() {
    const [expanded, setExpanded] = useState(false);

    return(
        <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            onClick={()=> setExpanded(!expanded)} >
            <TableCell component="th" scope="row" align="center"> </TableCell>
            {
                <>
                    <TableCell align="left">
                        <br/>
                    </TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="left"></TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                </>
            }
            <TableCell align="right"><ExpandMoreIcon/></TableCell>
       </TableRow>
    );
}

export default function LeaderboardTableRow(props: LeaderboardTableRowProps) {
    const {
        rank,
        clanName, playerName, level, xp, xp_max, 
        gold,
        def, str, hp, hp_max, regen,
        minutes_played, operatingSystem, playerId,
    } = props;

    const [expanded, setExpanded] = useState(false);

    // Rounds values e.g. 60.00333333334% --> 60%
    const nextLevelPercent = Math.floor(xp / xp_max * 100);

    const PlayerLink = () => (
        <Link to={`player/${playerName}`}>
            {playerName}
        </Link>
    );

    const PlayerCard = () => (
        <>
        <TableCell colSpan={4}>
            <Card sx={{ minWidth: 275 }}>
                <CardHeader
                    avatar={
                        <Avatar aria-label="stickman">
                            <img src={process.env.PUBLIC_URL + '/img/SKO_stick_jig_3_1.gif'} />
                        
                        </Avatar>
                    }
                    title={
                        <PlayerLink />
                    }
                    subheader={`Level: ${level}`}
                    action={
                        <Typography sx={{ mb: 1.5,  mt: 1.5  }} color="text.secondary">
                            Gold: 3.89B
                        </Typography>
                    }
                />
                <CardContent>
                    <Stack
                        direction="column"
                        divider={<Divider orientation="horizontal" flexItem />}
                        spacing={2}
                        >
                        <Typography component="div">
                        </Typography>
                        <Typography component="div">
                            health points: {hp_max}
                        </Typography>
                        <Typography component="div">
                            strength: {str}
                        </Typography>
                        <Typography component="div">
                            defense: {def}
                        </Typography>
                        <Typography component="div">
                            health regen: {regen}
                        </Typography>
                    </Stack>
                </CardContent>
            </Card>
        </TableCell>
    </>
    );

    return(
        <TableRow
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            onClick={()=> setExpanded(!expanded)} >
            <TableCell component="th" scope="row" align="center">{rank}</TableCell>
            {
                expanded?
                    <PlayerCard />
                :
                <>
                    <TableCell align="left">
                        <PlayerLink/>
                    </TableCell>
                    <TableCell align="left">{clanName}</TableCell>
                    <TableCell align="left">{level}</TableCell>
                    <TableCell align="right">{nextLevelPercent}%</TableCell>
                    <TableCell align="right">{operatingSystem}</TableCell>
                </>
            }
            <TableCell align="right">{expanded?<ExpandLessIcon/>:<ExpandMoreIcon/>}</TableCell>
       </TableRow>
    );
}