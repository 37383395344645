import React from 'react';
import './DownloadButton.css';
import Button from '@mui/material/Button';


function DownloadButton(props: any) {
  // Set animation if desired
  const textClass = "text" + (props['text-animation'] ? " animated" : "");
  const iconClass = "icon" + (props['icon-animation'] ? " animated" : "");
  console.log(textClass);
  console.log(iconClass);
  
  return (
    <Button className="opi-download-button" href={props.link}>
      {props.icon && <img alt="Download" className={iconClass} src={props.icon} />}
      {props.text && <span className={textClass}>{props.text}</span>}
    </Button>
  );
}

export default DownloadButton;

