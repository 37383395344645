
export default function News() {
	return (
		<>
			<img
				src="https://www.netanimations.net/fire-under-construction-animation.gif"
				alt="Under Construction"
			></img>

			<b>News go here :)</b>
		</>
	);
};