
export default function Donate() {
	return (
		<>
			<img
				src="https://www.netanimations.net/fire-under-construction-animation.gif"
				alt="Under Construction"
			></img>

			Donate to support Stick Knights Online! 
			Include the username of the character in Stick Knights Online you would like to receive a free gift! 

			<form action="https://www.paypal.com/donate" method="post" target="_top">
				<input type="hidden" name="hosted_button_id" value="YQABECKRPCNB4" />
				<input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
				<img alt="paypal" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
			</form>
		</>
	);
};