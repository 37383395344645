
export default function Home() {
	return (
		<>
			<img
				src="https://www.netanimations.net/fire-under-construction-animation.gif"
				alt="Under Construction"
			/>
			<b>home goes here</b>
		</>
	);
};